
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';

import Icon from 'UI/Icon.vue';
import Button from 'Control/Button.vue';
import Checkbox from 'Control/Checkbox.vue';
import TextInput from 'Control/TextInput.vue';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import WalletsApi from 'Apis/Wallets';
import CreateBankRequisitesRequestData from 'Lib/entities/walletExecutor/CreateBankRequisitesRequestData';
import ApiError from 'Entities/ApiError';
import AssetsBlockchainsDropdown from 'Control/AssetsBlockchainsDropdown.vue';
import CountryInput from 'Control/CountryInput.vue';
import router from '@/router';

interface Data {
    isUserAgree: boolean;
    hasCheckboxError: boolean;
    showTooltip: boolean;
    previousRoute: string;
    showSecondStep: boolean;
    variant: null | string;
    activeBeneficiaryNameIndex: number;
    formData: {
        alias: string;
        beneficiaryAddress: string;
        beneficiaryBankAddress: string;
        beneficiaryBankCity: string;
        beneficiaryBankCountry: string;
        beneficiaryBankCountryCode: string;
        beneficiaryBankName: string;
        beneficiaryBankPostalCode: string;
        beneficiaryCity: string;
        beneficiaryCountry: string;
        beneficiaryIban: string;
        beneficiaryPostalCode: string;
        beneficiarySwift: string;
        beneficiaryCountryCode: string;
    },
    errors: {
        alias: boolean;
        beneficiaryAddress: boolean;
        beneficiaryBankAddress: boolean;
        beneficiaryBankCity: boolean;
        beneficiaryBankName: boolean;
        beneficiaryBankPostalCode: boolean;
        beneficiaryCity: boolean;
        beneficiaryIban: boolean;
        beneficiaryPostalCode: boolean;
        beneficiarySwift: boolean;
        isUserAgree: boolean;
    };
}

interface Methods {
    addRequisite: () => void;
    next: () => void;
    nextStep: () => void;
}

interface Computed {
    currentUser: InternalUserResponse | null;
    isKycVerified: boolean;
    validBeneficiaryNames: string[];
}

export default Vue.extend<Data, Methods, Computed>({
    components: { CountryInput, AssetsBlockchainsDropdown, TextInput, Checkbox, Button, Icon },
    data() {
        return {
            isUserAgree: false,
            hasCheckboxError: false,
            showTooltip: true,
            previousRoute: '/wallets',
            showSecondStep: false,
            variant: null,
            activeBeneficiaryNameIndex: 0,
            formData: {
                alias: '',
                beneficiaryAddress: '',
                beneficiaryBankAddress: '',
                beneficiaryBankCity: '',
                beneficiaryBankCountry: '',
                beneficiaryBankCountryCode: '',
                beneficiaryBankName: '',
                beneficiaryBankPostalCode: '',
                beneficiaryCity: '',
                beneficiaryCountry: '',
                beneficiaryIban: '',
                beneficiaryPostalCode: '',
                beneficiarySwift: '',
                beneficiaryCountryCode: '',
            },
            errors: {
                alias: false,
                beneficiaryAddress: false,
                beneficiaryBankAddress: false,
                beneficiaryBankCity: false,
                beneficiaryBankName: false,
                beneficiaryBankPostalCode: false,
                beneficiaryCity: false,
                beneficiaryIban: false,
                beneficiaryPostalCode: false,
                beneficiarySwift: false,
                isUserAgree: false,
            },
        };
    },
    validations() {
        return {
            formData: {
                alias: {
                    required,
                },
                beneficiaryAddress: {
                    required,
                },
                beneficiaryBankAddress: {
                    required,
                },
                beneficiaryBankCity: {
                    required,
                },
                beneficiaryBankName: {
                    required,
                },
                beneficiaryBankPostalCode: {
                    required,
                },
                beneficiaryCity: {
                    required,
                },
                beneficiaryIban: {
                    required,
                },
                beneficiaryPostalCode: {
                    required,
                },
                beneficiarySwift: {
                    required,
                },
            },
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
        validBeneficiaryNames() {
            return this.currentUser?.possibleNames ?? [];
        },
    },
    methods: {
        async next() {
            if (this.showSecondStep) {
                await this.addRequisite();
            } else {
                this.nextStep();
            }
        },
        nextStep() {
            this.$v.$touch();
            if (!this.$v.formData.alias.$invalid) {
                this.showSecondStep = true;
            } else {
                this.errors.alias = true;
            }
        },
        async addRequisite() {
            try {
                this.$v.$touch();
                if (!this.$v.$invalid && this.isUserAgree) {
                    const startChallengePath = (this.$router as any).history.current.path;
                    const totp = await this.$store.dispatch('Auth/getMFAToken');
                    await router.replace(startChallengePath);
                    await WalletsApi.saveUserBankRequisites(new CreateBankRequisitesRequestData({
                        ...this.formData,
                        beneficiaryName: this.validBeneficiaryNames[this.activeBeneficiaryNameIndex],
                        totp,
                    }));
                    await this.$store.dispatch('Notificator/showSuccessNotification', 'Requisites has been successfully saved');
                    await this.$router.push(this.previousRoute);
                } else {
                    if (this.$v.formData.beneficiaryAddress.$invalid) {
                        this.errors.beneficiaryAddress = true;
                    }
                    if (this.$v.formData.beneficiaryBankAddress.$invalid) {
                        this.errors.beneficiaryBankAddress = true;
                    }
                    if (this.$v.formData.beneficiaryBankCity.$invalid) {
                        this.errors.beneficiaryBankCity = true;
                    }
                    if (this.$v.formData.beneficiaryBankName.$invalid) {
                        this.errors.beneficiaryBankName = true;
                    }
                    if (this.$v.formData.beneficiaryBankPostalCode.$invalid) {
                        this.errors.beneficiaryBankPostalCode = true;
                    }
                    if (this.$v.formData.beneficiaryCity.$invalid) {
                        this.errors.beneficiaryCity = true;
                    }
                    if (this.$v.formData.beneficiaryIban.$invalid) {
                        this.errors.beneficiaryIban = true;
                    }
                    if (this.$v.formData.beneficiaryPostalCode.$invalid) {
                        this.errors.beneficiaryPostalCode = true;
                    }
                    if (this.$v.formData.beneficiarySwift.$invalid) {
                        this.errors.beneficiarySwift = true;
                    }
                    if (!this.isUserAgree) {
                        this.errors.isUserAgree = true;
                    }
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong');
                }
            }
        },
    },
    mounted() {
        const {
            previousRoute,
            variant,
        } = this.$route.query;
        if (variant && variant === '1') {
            this.variant = '1';
        }
        this.previousRoute = previousRoute as string;
        this.$v.$reset();
    },
});
